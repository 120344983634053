import ContactForm from "../components/ContactForm";
import '../css/landing-page.css';

export default function LandingPage() {
    return (
        <div className="flex flex-row justify-center">
            {/* max-w-lg */}
            <div className="flex flex-wrap justify-center flex-row gap-y-16 mt-12">
                <div className="flex basis-full logo justify-center">
                    <div className="inline-block">💎 ShinySat.xyz</div>
                </div>
                <div className="flex flex-wrap basis-full justify-around gap-y-8 flex-row">
                    <div className="basis-full sm:basis-3/5 max-w-xs p-4">
                        <h1 className="hero mb-8">
                            <strong>Mint</strong> your own 
                            <br />
                            <strong>BTC digital art</strong>
                            <br /> 
                            in <strong><em>minutes</em></strong>
                        </h1>
                        <p className="mt-8">
                            Create NFTs on the Bitcoin Blockchain with <a href="https://docs.ordinals.com/overview.html" target="_blank" rel="noreferrer">Ordinals</a>.
                            Images & text are permanently stored on-chain, no IPFS links.
                        </p>
                    </div>
                    <div className="signup rounded-lg basis-full sm:basis-2/5 max-w-xs p-4">
                        <div className="p-2">
                            <h2 className="block mb-2"><em>Coming soon</em></h2>
                            <p className="block mb-10">
                                Be one of the first to mint a Bitcoin NFT. Subscribe for updates.
                            </p>
                            <div className="mb-6">
                                <ContactForm></ContactForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
