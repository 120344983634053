import { Routes, Route, Outlet, Link, BrowserRouter } from "react-router-dom";
import LandingPage from './pages/LandingPage'
import './css/font-degular.css'

export default function App() {
  return (
    <BrowserRouter>
      {/* Main content here */}
      <Routes>
        <Route index element={<LandingPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>

      {/* Footer here */}
    </BrowserRouter>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}