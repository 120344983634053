import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
export default function ContactForm() {
  const [state, handleSubmit] = useForm("xgeborln");
  if (state.succeeded) {
      return <p><b>Welcome to the ShinySat community &mdash; you'll be first to know when we launch.</b></p>;
  }
  return (
      <form onSubmit={handleSubmit}>
        <div className="flex flex-row flex-wrap justify-around">
            <input
                id="email"
                type="email" 
                name="email"
                className='input-text basis-full mb-4'
                placeholder="email@address.com"
            />
            <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
            />
            <button 
                type="submit" 
                className='input-button basis-5/6'
                disabled={state.submitting}>
                    Subscribe
            </button>
        </div>
    </form>
  );
}